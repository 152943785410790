<template>
  <ul class="systems__list">
    <li>
      <p class="systems__isolation-params">
        <span>R<sub>w</sub> = {{ source.rw }} {{ $t('message.units.dB') }}</span>
        <span v-if="source.lnw">L<sub>nw</sub> = {{ source.lnw }} {{ $t('message.units.dB') }}</span>
      </p>
    </li>
    <li class="systems__items" v-for="(material, index) of systemMaterials" :key="index">
      <span>{{ material.title }}</span>
      <span v-if="material.value">{{ material.value }} {{ material.unit }}</span>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'SystemItem',
  props: ['source'],
  computed: {
    systemMaterials() {
      return this.source.materials.length === 0
        ? []
        : this.source.materials.map((material) => ({
          title: material.title,
          value: material.thickness.value,
          unit: material.thickness.unitType
        }))
    }
  }
}
</script>

<style scoped lang="sass">
.systems
  &__isolation-params
    display: flex
    flex-flow: row wrap
    margin: 0 0 rem(5)
    gap: rem(20)
    @extend %16
    line-height: 1.3
  &__items
    display: flex
    gap: rem(16)
    align-items: center
    justify-content: space-between
    margin: 0
    padding: rem(12) 0
    border-bottom: 1px solid $border-color
    span
      flex-shrink: 0
      @extend %16
      line-height: 1.3
      text-align: left
      color: $main-text
      &:first-child
        flex-shrink: 1
        @extend %12
        color: $text-gray
</style>
