<template>
  <article
    ref="description"
    class="media-cart"
    :class="{
      'media-cart--vertical': verticalGrid,
      'media-cart--horizontal': horizontalGrid,
      'media-cart--active': active
    }"
  >
    <a class="media-cart__wrapper" @click="handle('select', source)">
      <p class="media-cart__image-wrapper">
        <img :src="source.image" alt="" class="media-cart__img"/>
        <SimpleButton
          class="media-cart__button media-cart__button--details"
          :color="'transparent-gray'"
          padding="10"
          :icon="'eye'"
          @btnClicked="handle('modal', source)"
        >
          <template v-slot:icon></template>
          <span>{{ $t('message.buttons.quick') }}</span>
        </SimpleButton>
      </p>
      <div class="media-cart__text-wrapper">
        <h6 class="media-cart__title">{{ getTitle(source.title) }}</h6>
        <p v-if="!isConstructionList" class="media-cart__description" :style="{ 'line-clamp': quantityLine }">
          {{ source.description }}
        </p>
        <SystemItem v-else :source="source"/>
      </div>
    </a>
  </article>
</template>

<script>
import SystemItem from '@/components/dump/SystemItem'
import SimpleButton from '@/components/dump/Simple-button'

export default {
  name: 'MediaCart',
  props: ['source', 'active', 'verticalGrid', 'horizontalGrid'],
  components: { SimpleButton, SystemItem },
  data: () => ({
    quantityLine: null
  }),
  methods: {
    getTitle(title) {
      return /(\S\+\S)|(\S\+\s)|(\s\+\S)/gm.test(title) ? title.replace('+', ' + ') : title
    },
    handle(evt, item) {
      this.$emit(evt === 'modal' ? 'triggerModal' : 'selectItem', item)
    }
  },
  mounted() {
    const description = this.$refs.description.querySelector('.media-cart__description')
    if (description) {
      const ro = new ResizeObserver((entries) => {
        for (const entry of entries) {
          const lineHeight = parseFloat(window.getComputedStyle(description).lineHeight)
          this.quantityLine = Math.round(entry.contentRect.height / lineHeight)
        }
      })
      ro.observe(description)
    }
  },
  computed: {
    isConstructionList() {
      return this.source.rw
    }
  }
}
</script>

<style lang="sass">
.media-cart
  display: grid
  &--vertical
    grid-template-areas: 'image' 'texts'
  &--horizontal
    grid-template-areas: 'image texts'
  &__wrapper
    border-radius: $block-radius
    border: 1px solid $border-color
    overflow: hidden
  &__image-wrapper
    grid-area: image
    display: flex
    align-items: center
    justify-content: center
    margin: 0
    padding: rem(12) rem(25) 0
    position: relative
    cursor: pointer
    overflow: hidden
  &__img
    min-height: rem(194)
    max-height: rem(194)
  &__text-wrapper
    grid-area: texts
    display: flex
    flex-flow: column nowrap
    max-height: rem(108)
    padding: 0 rem(20) rem(20)
    overflow: hidden
  &__title
    flex-grow: 1
    min-height: rem(42)
    margin: rem(4) 0 rem(8)
    @extend %16
    font-weight: 600
    line-height: 1.3
    overflow: hidden
    text-overflow: ellipsis
    display: -webkit-box
    -webkit-line-clamp: 2
    -webkit-box-orient: vertical
  &__description
    flex-grow: 0
    columns: 1
    margin: 0
    @extend %14
    text-align: left
    line-height: 1.3
    display: -webkit-box
    -webkit-box-orient: vertical
    -webkit-line-clamp: 2
    overflow: hidden
  &--active
    .media-cart__wrapper
      border: 1px solid $main-color
  &__button
    &--details
      display: none!important
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
      align-items: center
      min-width: auto
      @extend %14
      font-weight: 400
      span
        flex-shrink: 0
</style>
